<template>
    <b-container fluid>
        <b-row class="details-header px-0">
            <b-col xl="12">
                <b-row class="mb-2">
                    <b-col class="text-left">
                        <div class="d-inline-flex flex-wrap align-items-center">
                            <div class="mr-4 mb-2 mb-sm-0">
                                <b-icon
                                    class="mr-2"
                                    @click="goBack"
                                    icon="chevron-left"
                                    role="button"
                                ></b-icon>

                                <span class="text-uppercase text-caption heavy text-white-lighter">
                                    {{ t('surgeonDetails_SurgeonName') }}
                                </span>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <span class="header-last-name text-display word-wrap">
                            {{ doctor.lastName }}
                        </span>
                        <span class="text-display text-white-light word-wrap">
                            {{ doctor.firstName }}
                        </span>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row>
            <b-col xl="12">
                <router-view />
            </b-col>
        </b-row>
        <p class="font-weight-lighter mt-2">
            <small>{{ t('disclaimer') }} </small>
        </p>
    </b-container>
</template>
<script>
import {mapState, mapGetters} from 'vuex';
import get from 'lodash/get';
import {checkPermissions} from '@/utilities/permissions';

export default {
    name: 'SurgeonDetailsIndex',
    components: {},
    props: {
        doctorId: {
            type: [Number, String],
            default: () => null,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapState({
            doctor: (state) => state.doctors.selected,
        }),
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('permissions', ['permissions']),
    },
    methods: {
        goBack() {
            // Find the route object with the specified name
            const route = this.$router.options.routes.find((r) => r.name === 'SurgeonList');
            const requirePermissions = get(route, 'meta.permissions');
            if (this.checkPermissions(requirePermissions)) {
                this.$router.push({name: 'SurgeonList'});
            } else {
                this.$router.go(-1);
            }
        },
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
    },
    async mounted() {
        if (!this.doctorId || isNaN(this.doctorId)) {
            this.$router.push({
                name: 'NotFound',
            });
            return;
        }
        await this.blockingRequest('doctors/fetch', this.doctorId);
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

.details-header {
    padding: 8px 0;
    background-color: $primary;
    color: $white;
    min-height: 103px;
}

.header-last-name {
    font-weight: 500;
    font-size: 4.28rem;
    line-height: 3.42rem;
}
</style>
